.slider.slider.slider.slider.slider {
  all: initial;
  width: 100%;
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  height: 1.5rem;
  -webkit-tap-highlight-color: transparent !important;

  span {
    transition: all 250ms;
  }

  .active {
    height: 0.5rem;
    border-radius: 0.25rem;
    background-color: var(--color-text);
    opacity: 0.8;
  }

  .inactive {
    background-color: var(--color-text);
    height: 0.25rem;
    border-radius: 0.125rem;
    opacity: 0.6;
  }

  .pointer {
    position: absolute;
    transform: translateX(-50%);
    width: 1.25rem;
    height: 1.25rem;
    background-color: var(--color-text);
    border-radius: 0.625rem;
    cursor: pointer;
    z-index: 2;

    &:hover {
      transform: scale(1.125) translateX(-50%);
    }

    &:active {
      transform: scale(1.25) translateX(-50%);
    }
  }

  ul {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      flex: 1;
    }
  }
}
