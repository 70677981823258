html,
body {
  user-select: none;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  line-height: 1.25;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent !important;
  background-color: var(--color-background);
  color: var(--color-text);
}

#root {
  position: fixed;
}

html[data-theme="light"] {
  --color-primary: #000000;
  --color-text: #000000;
  --color-background: #f7f7f7;
  --color-search-background: #eaeaea;
  --color-sub-background: #ffffff;
  --color-chapters-background: #ececec;
  --color-chapters-text: #929292;
  --color-warning: #ff4545;
}

html[data-theme="dark"] {
  --color-primary: #ffffff;
  --color-text: #c3c3c3;
  --color-background: #2b2b2b;
  --color-search-background: #424242;
  --color-sub-background: #373737;
  --color-chapters-background: #373737;
  --color-chapters-text: #7f7f7f;
  --color-warning: #cc3300;
}

.crash {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  gap: 1rem;

  p,
  h4,
  pre {
    margin: 0;
  }

  pre {
    border-radius: 1rem;
    background-color: var(--color-sub-background);
    padding: 1rem;
    color: var(--color-warning);
    max-width: calc(100% - 4rem);
    white-space: pre-wrap;
    max-height: 70%;
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  div {
    display: flex;
    gap: 0.5rem;
  }

  button {
    border-radius: 1rem;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    outline: none;
    color: var(--color-text);
    background-color: var(--color-sub-background);
    border-color: var(--color-text);
    border-width: 1.5px;
    border-style: solid;
    font-weight: bold;
    cursor: pointer;

    &:active {
      opacity: 0.7;
    }
  }
}

.background {
  height: 100%;
  width: 100%;
  background-color: #00000044;
  position: absolute;
  top: 0;
  left: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 500ms;
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.scale-enter {
  transform: scale(0);
}

.scale-enter-active {
  transition: transform 500ms;
  transform: scale(1);
}

.scale-exit-active {
  transform: scale(0);
  transition: transform 500ms;
}

.slide-y-enter {
  transform: translateY(100%);
}

.slide-y-enter-active {
  transform: translateY(0);
  transition: transform 500ms;
}

.slide-y-exit-active {
  transform: translateY(100%);
  transition: transform 500ms;
}

.slide-y-reverse-enter {
  transform: translateY(-100%);
}

.slide-y-reverse-enter-active {
  transform: translateY(0);
  transition: transform 500ms;
}

.slide-y-reverse-exit-active {
  transform: translateY(-100%);
  transition: transform 500ms;
}

.slide-x-enter {
  transform: translateX(100%);
}

.slide-x-enter-active {
  transform: translateX(0);
  transition: transform 500ms;
}

.slide-x-exit-active {
  transform: translateX(100%);
  transition: transform 500ms;
}
