.warning {
  background-color: var(--color-background);
  opacity: 0.8;
  backdrop-filter: blur(5px);
  border-radius: 2rem;
  display: flex;
  padding: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;

  h2 {
    padding: 0;
    margin: 0;
  }
}
