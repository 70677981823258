.login {
  display: flex;
  position: relative;
  flex-direction: column;
  width: calc(
    100vw - max(1rem, env(safe-area-inset-right)) -
      max(1rem, env(safe-area-inset-left))
  );
  height: 100vh;
  padding-right: max(1rem, env(safe-area-inset-right));
  padding-left: max(1rem, env(safe-area-inset-left));
  background-color: var(--color-background);
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);
    width: calc(
      100vw - max(1rem, env(safe-area-inset-right)) -
        max(1rem, env(safe-area-inset-left))
    );
    max-width: 30rem;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      fill: var(--color-text);
      width: 35%;
    }

    .row {
      display: flex;
      margin-bottom: 1rem;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }

    img {
      width: 10rem;
    }

    h2 {
      margin: 0;
      margin-bottom: 3rem;
    }
  }
}
