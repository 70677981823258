#loader {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .loader-enter {
    transform: scale(0);
  }

  .loader-exit,
  .loader-enter-done {
    transform: scale(0.75);
  }

  .loader-enter-active {
    transition: transform 250ms;
    transform: scale(0.75);
  }

  .loader-exit-active {
    transform: scale(0);
    transition: transform 250ms;
  }

  .loader {
    background-color: var(--color-background);
    opacity: 0.8;
    backdrop-filter: blur(5px);
    aspect-ratio: 1;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.5rem;
  }
}
