.details {
  height: 100vh;
  width: calc(
    100vw - env(safe-area-inset-left) - env(safe-area-inset-right) - 2rem
  );
  padding: 1rem;
  padding-top: 0;
  display: flex;
  margin: 0;
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);
  background-color: var(--color-background);
  overflow: hidden;
  scrollbar-width: none;
  gap: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }

  .leftContent {
    height: 100%;
    width: 100%;
    flex: 1;
  }

  .rightContent {
    flex: 2;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .share {
    cursor: pointer;
  }

  .scrollable {
    width: 100%;
    height: calc(100% - max(1rem, env(safe-area-inset-top)) - 2rem);
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .thumbnail {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;

      .imgWrapper {
        border-radius: 1rem;
        aspect-ratio: 9/12;
        width: 95%;
      }
    }

    h2.title {
      user-select: all;
      cursor: text;
    }

    .title {
      margin: 0;
      margin-top: 1rem;
      text-align: center;
      padding: 0;
      font-size: 2rem;
    }

    .author {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transform: translateY(-0.25rem);
      font-size: 1rem;
      color: #888888;
      cursor: pointer;

      li {
        margin-right: 0.25rem;
      }
    }

    .continue {
      background-color: var(--color-text);
      border: 0.125rem solid var(--color-text);
      color: var(--color-background);
      font-size: 1.25rem;
      font-weight: bold;
      padding: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      border-radius: 2.5rem;
      cursor: pointer;
      white-space: nowrap;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 0.5rem;
      }
    }

    .otherButtons {
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-top: 0.5rem;
      display: flex;
      font-size: 1.25rem;
      font-weight: bold;
      gap: 0.5rem;

      li {
        border: 0.125rem solid var(--color-text);
        border-radius: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        border-radius: 2.5rem;
        background-color: var(--color-background);
        cursor: pointer;
        display: flex;
        align-items: center;
        flex: 1;
      }
    }

    .divider {
      width: 100%;
      height: 2px;
      background-color: var(--color-chapters-background);
      margin-top: 2rem;
      margin-bottom: 1rem;
      border-radius: 1px;
    }

    .description {
      margin-top: 2rem;
      overflow: hidden;

      h3 {
        margin: 0;
        margin-bottom: 0.75rem;
        padding: 0;
      }

      span {
        margin-top: 0;
        text-align: justify;
        color: var(--color-chapters-text);
        white-space: pre-line;
        user-select: text;
      }
    }

    .info {
      list-style-type: none;
      padding: 0;
      margin: 0;
      width: calc(100% - 2rem);
      display: flex;
      overflow: hidden;
      overflow-x: scroll;
      padding-left: 1rem;
      padding-right: 1rem;

      &::-webkit-scrollbar {
        display: none;
      }

      .selectable {
        user-select: all;
        cursor: text;
      }

      .vDivider {
        background-color: var(--color-chapters-background);
        min-height: 5rem;
        margin-left: 1rem;
        margin-right: 1rem;
        width: 2px;
        align-self: center;
        flex-shrink: 0;
      }

      li:not(.vDivider) {
        min-width: 5rem;
        max-width: 10rem;
        display: flex;
        flex-direction: column;
        align-content: center;
        flex-shrink: 0;

        .title {
          font-size: 1rem;
          margin: 0;
        }

        .content {
          flex: 1;
          font-weight: bold;
          font-size: 1.25rem;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
      }
    }
  }
}
