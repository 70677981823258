.download {
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: var(--color-background);
  top: 50%;
  left: 50%;
  width: 60%;
  height: 70%;
  border-radius: 1rem;
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;

  .topActions {
    display: flex;
    justify-content: space-between;
    position: relative;

    b {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    span {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 100vh) {
  .download {
    bottom: 0;
    left: 0;
    top: auto;
    width: calc(100% - 2rem);
    height: calc(85% - env(safe-area-inset-bottom));
    padding-bottom: 0;
    transform: none;
    border-radius: 1rem 1rem 0 0;
  }

  .chapters {
    padding-bottom: max(1rem, env(safe-area-inset-bottom));
  }
}
