.userSettings {
  width: calc(
    100vw - max(1rem, env(safe-area-inset-right)) -
      max(1rem, env(safe-area-inset-left))
  );
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-right: max(1rem, env(safe-area-inset-right));
  padding-left: max(1rem, env(safe-area-inset-left));
  background-color: var(--color-background);
  .subSettings {
    background-color: var(--color-sub-background);
    border-radius: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    button {
      padding: 0.5rem;
    }
  }

  .info {
    padding: 1rem;
    gap: 0.5rem;
  }
}
