#main {
  height: 100vh;
  width: calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right));
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);

  #menuBar {
    width: calc(100% - 2rem);
    height: 3rem;
    margin-top: max(1rem, env(safe-area-inset-top));
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;

    div {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    .icon {
      display: block;
      height: 100%;
      aspect-ratio: 1;
      cursor: pointer;
    }

    .appName {
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex: 1;
      gap: 0;
      -webkit-transform: translate3d(0, 0, 0);

      h2,
      h5 {
        margin: 0;
        white-space: nowrap;
      }

      h5 {
        opacity: 0.5;
        font-size: 0.75rem;
      }
    }

    #actions {
      flex-grow: 0;
      gap: 0.5rem;
      div {
        cursor: pointer;
      }
    }

    svg {
      height: 100%;
      fill: var(--color-text);
      aspect-ratio: 1;
    }
  }

  #content {
    height: calc(100vh - 2rem - max(1rem, env(safe-area-inset-top)));
    width: calc(100% - 2rem);
    overflow-y: scroll;
    padding-left: 1rem;
    padding-right: 1rem;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;

    &::-webkit-scrollbar {
      display: none;
    }

    #historyPreview {
      display: flex;
      width: 100%;
      height: 7rem;
      padding: 0;
      margin: 0;
      list-style-type: none;
      gap: 1rem;
      flex-shrink: 0;
      overflow-x: scroll;
      overflow-y: hidden;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        height: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        .imgWrapper {
          flex: 1;
          width: 8rem;
          border-radius: 0.5rem;

          img {
            object-position: 50% 25%;
            pointer-events: none;
          }
        }

        h4 {
          margin: 0;
          margin-top: 0.125rem;
          width: 8rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: normal;
        }

        p {
          width: 8rem;
          margin: 0;
          opacity: 0.4;
          font-size: 0.75rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .viewAll {
        height: calc(100% - 1rem);
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        background-color: var(--color-sub-background);
        padding: 0.5rem;
        border-radius: 0.5rem;

        span {
          padding: 0.5rem;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    #subMenuBar {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      margin-top: 0.5rem;

      div {
        cursor: pointer;
        transform: translateY(0.25rem);
      }

      .updating {
        opacity: 0.5;
      }

      #filters {
        flex: 1;
        list-style-type: none;
        padding: 0;
        display: flex;
        margin: 0;
        align-items: flex-end;
        font-weight: bold;
        gap: 0.5rem;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none;
        padding-right: 1rem;
        height: 2.25rem;
        -webkit-transform: translate3d(0, 0, 0);

        &::-webkit-scrollbar {
          display: none;
        }

        li {
          opacity: 0.5;
          font-size: 1rem;
          cursor: pointer;
          transition: all 0.35s;
        }

        .selected {
          opacity: 1;
          font-size: 2rem;
          cursor: auto;
          transform: translateY(0.25rem);
        }
      }
    }

    #collectionsContent {
      flex: 1;
      flex-grow: 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(calc(10rem), 1fr));
      column-gap: 1rem;
      row-gap: 1rem;
      margin-bottom: calc(1rem + max(env(safe-area-inset-bottom) + 1rem));
      margin-top: 1rem;
    }

    #downloadTasks {
      flex: 1;
      flex-grow: 0;
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      margin-top: 1rem;
      gap: 1rem;
      flex-direction: column;

      li {
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        .imgWrapper {
          border-radius: 0.5rem;
          aspect-ratio: 9/12;
          width: 6rem;
        }

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .info {
          width: calc(100% - 7rem);

          .options {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            margin-top: 1rem;

            button {
              flex: 1;
            }
          }
        }

        b {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p {
          opacity: 0.4;
          margin: 0;
          margin-top: 0.25rem;
        }
      }
    }

    #empty {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      opacity: 0.5;
      font-size: 1.5rem;

      p {
        transform: translateY(-3rem);
      }
    }
  }
}
