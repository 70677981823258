.swipeableWrapper {
  height: 100vh;
  width: 100vw;
  position: relative;

  .swipeable {
    height: 100%;
    width: 100%;
  }
}
