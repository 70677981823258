.popUpReader {
  background-color: var(--color-background);
  padding: 1rem;
  border-radius: 1rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  h2 {
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  div {
    display: flex;
  }
}

body:has(#new-window-container) {
  overflow-y: auto;
}

#new-window-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    text-align: center;
    margin: 1rem;
    margin-bottom: 0;
  }

  p {
    padding: 0;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    line-height: 0;
  }

  li {
    .imgWrapper {
      display: flex;
    }

    &.hidden {
      display: none;
    }
  }

  .controller {
    width: calc(100% - 2rem);
    padding: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    span {
      border-radius: 0.5rem;
    }

    b {
      display: flex;
    }

    &.pageController {
      padding-bottom: 0;
    }
  }

  .placeholder {
    width: 100vw;
    background-color: var(--color-search-background);
  }
}
