.mangasList {
  flex: 1;
  margin-top: 1rem;
  position: relative;
  padding-bottom: max(env(safe-area-inset-bottom), 1rem);
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(10rem), 1fr));
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .spin {
    display: flex;
    justify-content: center;
  }
}
