.imgWrapper {
  overflow: hidden;
  background-color: var(--color-search-background);
  flex-shrink: 0;
  line-height: 0;
  position: relative;

  .src {
    position: absolute;
    bottom: 0;
    background-color: var(--color-background);
    opacity: 0.9;
    font-size: 0.75rem;
    width: 100%;
    margin: 0;
    line-height: initial;
    word-break: break-all;
    user-select: all;
    cursor: text;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imgLoader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
