.snapToPage {
  scroll-snap-type: y mandatory;
}

.read {
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: var(--color-background);

  &::-webkit-scrollbar {
    display: none;
  }

  &.centered {
    position: relative;

    .images {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .spacer {
    width: 50%;
  }

  img {
    pointer-events: none;
  }

  .images {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);

    ul {
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;

      li {
        scroll-snap-align: center;

        &.hidden {
          display: none;
        }
      }
    }
  }
}
