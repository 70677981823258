.mangaPreview {
  padding: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imgWrapper {
    width: 100%;
    border-radius: 0.5rem;
    aspect-ratio: 9/12;
  }

  h3 {
    margin: 0;
    margin-top: 0.25rem;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h5 {
    margin: 0;
    opacity: 0.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tag {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    border-radius: 0.5rem 0 0 0;
    overflow: hidden;

    div {
      color: white;
      font-weight: bold;
      font-size: 1rem;
      padding: 0.125rem;
      padding-left: 1rem;
      padding-right: 1rem;
      backdrop-filter: blur(10px);
      border-radius: 0 0.5rem 0.5rem 0;
      display: flex;
      justify-content: center;
      word-break: break-all;
      align-items: center;
      min-height: 1.5rem;
      margin-right: 0.5rem;
      user-select: all;
      cursor: text;

      &:first-child {
        border-radius: 0.5rem 0 0.5rem 0;
      }
    }

    .tagEnded {
      background-color: #ff0055aa;
      user-select: none;
      cursor: inherit;
    }

    .driverID {
      background-color: #006affaa;
      top: 2rem;
    }

    .mangaID {
      background-color: #006affaa;
      top: 4rem;
    }

    .tagUpdated {
      background-color: #00ff22aa;
      user-select: none;
      cursor: inherit;
    }
  }
}
