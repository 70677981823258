.downloadProgress {
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: var(--color-background);
  top: 50%;
  left: 50%;
  min-width: 15rem;
  max-width: 55%;
  max-height: 65%;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 1rem;

      span {
        flex-shrink: 0;
      }

      b {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  div {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;

    button {
      flex: 1;
    }
  }
}
