// To ensure it have the highest priority
.input.input.input.input.input {
  all: initial;
  font: inherit;
  color: inherit;
  background-color: var(--color-sub-background);
  border-radius: 0.5rem;
  padding: 0.5rem;
  gap: 0.5rem;
  display: flex;
  box-sizing: border-box;
  align-items: center;

  &.fullWidth {
    flex: 1;
    width: 100%;
  }

  &.outlined {
    border: var(--color-text) 0.5px solid;
  }

  span {
    all: initial;
    color: inherit;
    display: flex;
  }

  .clear {
    cursor: pointer;
  }

  input,
  textarea {
    flex: 1;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    outline: none;
    background-color: transparent;
    border: none;
    color: inherit;
    font-size: 1rem;
    resize: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
