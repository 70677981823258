.history {
  width: calc(
    100vw - max(1rem, env(safe-area-inset-right)) -
      max(1rem, env(safe-area-inset-left))
  );
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-right: max(1rem, env(safe-area-inset-right));
  padding-left: max(1rem, env(safe-area-inset-left));
  background-color: var(--color-background);
  .actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    div {
      cursor: pointer;
    }
  }

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-3rem);
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    font-size: 1.5rem;
  }

  .historyWrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    flex: 1;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .historyContent {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    column-gap: 1rem;
    row-gap: 1rem;
    padding-bottom: max(env(safe-area-inset-bottom), 1rem);
  }

  .record {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 1rem;

    .imgWrapper {
      width: 8rem;
      border-radius: 0.5rem;
      aspect-ratio: 9/12;
      flex-shrink: 0;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      min-width: 0;

      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        margin-bottom: 1rem;
        font-weight: bold;
      }

      h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        opacity: 0.4;
        font-weight: normal;
      }

      h5 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        opacity: 0.3;
        font-weight: normal;
      }

      .debugInfo {
        margin-top: 0.5rem;

        p {
          color: #006affaa;
          font-weight: bold;
          margin: 0;
          font-weight: normal;
        }
      }
    }

    .continue {
      cursor: pointer;
      flex-shrink: 0;
    }
  }
}
