// To ensure it have the highest priority
.button.button.button.button.button {
  all: initial;
  font: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-text);
  color: var(--color-background);
  padding: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 0.5rem;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  -webkit-tap-highlight-color: transparent !important;
  transition: all 250ms;

  @mixin outlined($color) {
    border: $color 1px solid;
    background-color: transparent;
    color: $color;
    font-weight: normal;
  }

  &.outlined {
    @include outlined(var(--color-text));
  }

  &.warning {
    background-color: var(--color-warning);
  }

  &.outlined.warning {
    @include outlined(var(--color-warning));
  }

  &.clear {
    background-color: transparent;
    color: var(--color-text);
    font-weight: normal;
    padding: 0;
  }

  &.fullWidth {
    flex: 1;
    width: calc(100% - 2rem);
  }

  &.disabled {
    opacity: 0.4;
  }

  &:not(.disabled):hover {
    opacity: 0.8;
    transform: scale(1.125);
  }

  &:not(.disabled):active {
    opacity: 0.6;
    transform: scale(1.25);
  }

  &.fullWidth:not(.disabled):hover {
    transform: scale(1.025);
  }

  &.fullWidth:not(.disabled):active {
    transform: scale(1.05);
  }
}
