.select.select.select.select.select {
  all: initial;
  color: inherit;
  font: inherit;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border: var(--color-text) 1px solid;
  border-radius: 0.5rem;
  padding: 0.125rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
  gap: 0.25rem;
  transition: all 250ms;
  -webkit-tap-highlight-color: transparent !important;

  span {
    display: flex;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &:hover {
    opacity: 0.8;
    transform: scale(1.125);
  }
}
