.manageThemes {
  height: 100vh;
  width: calc(
    100vw - max(1rem, env(safe-area-inset-right)) -
      max(1rem, env(safe-area-inset-left))
  );
  padding-right: max(1rem, env(safe-area-inset-right));
  padding-left: max(1rem, env(safe-area-inset-left));
  padding-bottom: max(env(safe-area-inset-bottom), 1rem);
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;

  .topBar {
    div {
      cursor: pointer;
    }
  }

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    font-size: 1.5rem;
  }

  .themes {
    list-style-type: none;
    padding: 0;
    padding-bottom: max(env(safe-area-inset-bottom), 2rem);
    margin: 0;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 1rem;
    row-gap: 1rem;
    overflow-y: scroll;
    overflow: hidden;
    scrollbar-width: none;

    span {
      color: var(--color-text);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      background-color: var(--color-sub-background);
      padding: 1rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      span {
        user-select: all;
      }

      .options {
        display: flex;
        gap: 1rem;
        margin-top: 0.5rem;
      }
    }
  }
}
